import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TRACK_EVENTS } from 'constants/trackEvents';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { StyledConvertTicket } from './style';
import useTexts from './useTexts';
import { CONSTANTS } from '../../constants';
import ConversionPrompt from './conversionPrompt';
import { useHandleTicketLock, useTicketLockStatus } from '../../../../remote-state/ticketServiceHooks';
import { selectActiveUser } from '../../../../store/userSlice';

function ConvertTicket(props) {
    const { srId, srType, isFromQueue } = props;
    const {
      convertToRequestText,
      convertToIncidentText,
    } = useTexts(srId);

    const [openPrompt, setOpenPrompt] = useState(false);
    const trackEvents = useAnalytics();

    const isIncident = srType === CONSTANTS.INCIDENT_TYPE;

    function getQueueConversionEvent() {
      return srType === CONSTANTS.INCIDENT_TYPE ? TRACK_EVENTS.CONVERT_TO_REQUEST_QUEUE : TRACK_EVENTS.CONVERT_TO_INCIDENT_QUEUE;
    }

    function getTicketPageConversionEvent() {
      return srType === CONSTANTS.INCIDENT_TYPE ? TRACK_EVENTS.CONVERT_TO_REQUEST_TICKET_PAGE : TRACK_EVENTS.CONVERT_TO_INCIDENT_TICKET_PAGE;
    }
    const userAccount = useSelector(selectActiveUser);

    const convertionPendoEvent = isFromQueue ? getQueueConversionEvent() : getTicketPageConversionEvent();
    const { mutate: lockSR } = useHandleTicketLock(srId);
    const { data: lockingDetails } = useTicketLockStatus(srId);

    const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
    const handleClickOpen = () => {
      trackEvents(convertionPendoEvent, { srId });
      setOpenPrompt(true);
      if(!isTicketLocked && !isFromQueue) {
        lockSR({ username: userAccount?.username, srId, queueLocking: false });
      }
    };

    return (
      <StyledConvertTicket onClick={handleClickOpen}>
        <button className="convert-ticket" data-testid="convert-ticket-button">
          <span className="convert-ticket-span" data-testid="convert-ticket-span">{ isIncident ? convertToRequestText : convertToIncidentText || 'Convert Ticket'}</span>
        </button>
        { openPrompt &&
          <div data-testid="conversion-prompt">
            <ConversionPrompt
                open={openPrompt}
                setPromptOpen={setOpenPrompt}
                isFromQueue={isFromQueue}
                {...props}
            />
          </div>
        }
      </StyledConvertTicket>
    );
}

export default ConvertTicket;