import { Menu, styled } from '@mui/material';

export const commonMenuStyles = (theme) => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 8px 0px rgba(118, 130, 150, 0.35)',
  },
  '& span': {
    flexGrow: 0,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontSize: theme.fontSize.main,
    lineHeight: 1.25,
    fontFamily: theme.fonts.robotoRegular,
    color: theme.palette.text.title_txt,
  },
  '& .MuiButtonBase-root.MuiMenuItem-root:hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
  },
});

export const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => !['menuStyles', 'amountOfOpenButtons'].includes(prop),
})(({ theme, menuStyles }) => {
  const divider = {
    content: '""',
    width: '100%',
    height: '1px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: theme?.palette?.text?.disable_txt,
  };

  return {
    ul: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
      padding: theme.spacing(1),
      li: {
        position: 'relative',
        borderRadius: theme?.shape?.border?.borderRadius,
        padding: `12px ${theme.spacing(1)}`,
        width: '144px',
      },
    },
    '.top-actions': {
      position: 'relative',
      '&::after': divider,
      padding: `4px ${theme.spacing(1)} 7px`,
    },
    ...commonMenuStyles(theme),
    ...menuStyles,
  };
});

export const StyledActions = styled('div', {
  shouldForwardProp: (props) => !['btnClassName', 'isActive', 'isArchived'].includes(props),
})(({ theme, btnClassName, isActive, isArchived }) => ({
  '& .MuiButtonBase-root': {
    border: 'none',
    backgroundColor: isActive ? 'rgba(149, 169, 201, 0.30)' : 'transparent',
    cursor: 'pointer',
    padding: '6px',
    height: '32px',
    width: '32px',
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: 'rgba(149, 169, 201, 0.30)',

      '& .icon': {
        fill: theme.palette.icons.icons_grey_v1,
      },
    },
  },

  [`& .${btnClassName}.active`]: {
    display: 'flex',
  },
  '.delete-sr-action-btn': {
    padding: '3px',
    width: '22px',
    height: '22px',
  },

  ...(isArchived && {
    zIndex: 1000,
  }),
}));

export const StyledBottomActions = styled('div', {
  shouldForwardProp: (props) => !['isQueuePage'].includes(props),
})(({ isQueuePage }) => ({
  paddingLeft: isQueuePage && '8px',
}));
