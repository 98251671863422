import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';

export const StyledDuplicateTicket = styled(MenuItem)(() => ({
  '& .duplicate-ticket': {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const StyledList = styled(List)(() => ({
  padding: '0',
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '5px 0',
  alignItems: 'start',
  marginBottom: '10px',

  '.MuiListItemIcon-root': {
    minWidth: '15px',
    marginTop: '4px',

    '.MuiSvgIcon-root': {
      fontSize: theme.fontSize.xSmall,
      color: 'black',
    }
  }
}));
