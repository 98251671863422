import { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { setToasterMessage } from 'store/globalSlice';
import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as ChevronDown } from 'images/icons/chevron_down.svg';
import { Box } from '@mui/material';
import Tooltip from 'common/components/tooltip';
import { useTheme } from '@mui/styles';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { TICKET_EVENTS } from 'constants';
import { QUERIES_KEYS } from 'constant';
import { TRACK_EVENTS } from 'constants/trackEvents';
import useTexts from '../useTexts';
import { CONSTANTS } from '../../../constants';
import { DropdownSelect, useDropdownControl } from '../../../../../common/components/dropdownSelect';
import {
  buildTemplatesListForDropdown,
  useConvertSR,
  useIsSrPage,
  useTemplateListForDropdown,
  useTicketTemplateData,
} from '../../../../../remote-state/ticketServiceHooks';
import { SourcePageEnum } from '../../../../templateList/sourcePageEnum';
import { getTemplatesList } from '../../../../../services/templateService';

import {
  messagePromptStyle,
  StyledTemplateDropdownValue,
  StyledTemplateFieldWrapper,
  StyledTemplateToConvertWrapper,
} from './style';

function ConversionPrompt(props) {
  const { handleCloseParent, srId, srType, open, setPromptOpen, isFromQueue } = props;
  const {
    convertIncidentToRequestTitle,
    convertRequestToIncidentTitle,
    cancelBtnText,
    convertBtnText,
    wasConvertedMessage,
    errorInConvertedMessage,
    incidentTemplatesTitle,
    requestTemplatesTitle,
    workflowWarningText,
    viewSrText,
  } = useTexts(srId);

  const isSrPage = useIsSrPage();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { convertSrType } = useConvertSR();
  const trackEvents = useAnalytics();

  const isIncident = srType === CONSTANTS.INCIDENT_TYPE;
  const templateType = isIncident ? CONSTANTS.REQUEST_TYPE_IN_TEXT : CONSTANTS.INCIDENT_TYPE_IN_TEXT;
  const { anchor, handleOpen, handleClose } = useDropdownControl();

  const columnFilters = [{ id: 'srType', value: [templateType] }];
  const sort = { id: 'templateName' };
  const templateListResponse = useTemplateListForDropdown(sort, columnFilters, true)?.data;
  const templatesList = useMemo(() => templateListResponse?.values || [], [templateListResponse?.values]);

  const defaultTemplate = useTicketTemplateData(templateType, true);
  const [currentTemplate, setCurrentTemplate] = useState(defaultTemplate?.data);

  let timeOutId;

  useEffect(() => {
    setCurrentTemplate(defaultTemplate?.data);
  }, [defaultTemplate?.data]);

  const handleClickClose = () => {
    setPromptOpen(false);
    trackEvents(
      isIncident
        ? TRACK_EVENTS.GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST
        : TRACK_EVENTS.GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT,
      { srId },
    );
    handleCloseParent();
  };

  const handleError = () => {
    setPromptOpen(false);
  };

  const handleConvert = async () => {
    trackEvents(
      isIncident
        ? TRACK_EVENTS.CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST
        : TRACK_EVENTS.CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT,
      { srId },
    );
    try {
      const srTypeToConvert = isIncident ? CONSTANTS.REQUEST_TYPE : CONSTANTS.INCIDENT_TYPE;
      const payload = {
        id: srId,
        templateId: currentTemplate?.id,
        srType: srTypeToConvert,
        ...(isFromQueue ? { queueUpdate: isFromQueue } : {}),
      };
      await convertSrType(payload);
      setPromptOpen(false);
      if (isSrPage) {
        dispatch(setToasterMessage({ message: `${wasConvertedMessage()}` }));
        timeOutId = setTimeout(() => {
          window.location.href = window.location.pathname + window.location.search;
        }, 3000);
      } else {
        dispatch(
          setToasterMessage({
            message: `${wasConvertedMessage()}. <span style="color:${
              theme?.palette?.text?.blue_user_name
            };text-decoration: underline;font-family:${theme?.fonts?.robotoRegular};"> ${viewSrText}</span>`,
            onClickFunctionName: TICKET_EVENTS.TICKET_CREATED_TOAST_CLICKED,
            id: srId,
            showFor: 5000,
          }),
        );
      }
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.QUEUE_DATA] });
      if (!isSrPage) {
        queryClient.invalidateQueries(['srId', String(srId)]);
      }
      trackEvents(TRACK_EVENTS.CONVERSION_SUCCEEDED, { srId });
      handleClickClose();
    } catch {
      trackEvents(TRACK_EVENTS.CONVERSION_FAILED, { srId });
      dispatch(setToasterMessage({ message: errorInConvertedMessage(), type: 'error' }));
      console.log('can`t convert');
      handleError();
    }
  };

  const handleTemplateChanged = useCallback(
    (templateId) => {
      const selectedTemplate = templateListResponse.list.filter((t) => String(t.id) === String(templateId));
      if (selectedTemplate.length > 0) {
        setCurrentTemplate(selectedTemplate[0]);
      }
    },
    [templateListResponse],
  );

  const handleChange = useCallback(
    (val) => {
      handleTemplateChanged(val.id);
      handleClose();
    },
    [handleTemplateChanged, handleClose],
  );

  const selection = useMemo(() => (currentTemplate?.id ? [currentTemplate?.id] : []), [currentTemplate?.id]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        getTemplatesList({
          page: 0,
          size: 50,
          columnFilters: [
            {
              id: 'srType',
              value: [templateType],
            },
            {
              id: 'templateName',
              value: query,
            },
          ],
          sort: {
            id: 'templateName',
          },
          sourcePage: SourcePageEnum.CREATE_TICKET_PAGE,
          excludeSubTypes: true,
        }).then((res) => buildTemplatesListForDropdown(res)),
    }),
    [templateType],
  );

  useEffect(
    () => () => {
      clearTimeout(timeOutId);
    },
    [timeOutId],
  );

  return (
    <MessagePrompt
      open={open}
      title={isIncident ? convertIncidentToRequestTitle() : convertRequestToIncidentTitle()}
      btnOkText={convertBtnText}
      btnCancelText={cancelBtnText}
      onOkClick={handleConvert}
      showCancelBtn
      onClose={handleClickClose}
      showIcon={false}
      style={messagePromptStyle}
      workflowWarningText={isIncident ? '' : workflowWarningText}
    >
      <StyledTemplateToConvertWrapper data-testid="conversion-message-prompt">
        <div className="convert-template-title" data-testid="template-list-title">
          {isIncident ? requestTemplatesTitle : incidentTemplatesTitle}
        </div>
        <Box onClick={handleOpen}>
          <StyledTemplateFieldWrapper data-testid="template-field">
            <StyledTemplateDropdownValue data-testid="template-dropdown">
              <Tooltip
                data-testid="template-title"
                isTruncatedText
                text={currentTemplate?.templateName}
                placement="top-start"
              />
              <ChevronDown style={{ display: 'flex', flexShrink: 0, marginLeft: '4px' }} />
            </StyledTemplateDropdownValue>
          </StyledTemplateFieldWrapper>
        </Box>
        <DropdownSelect
          isWideDropdown
          noDivider
          options={templatesList}
          selection={selection}
          handleChange={handleChange}
          handleClose={handleClose}
          anchor={anchor}
          handleOpen={handleOpen}
          sortBy="value"
          backendQueryConfig={backendQueryConfig}
        />
      </StyledTemplateToConvertWrapper>
    </MessagePrompt>
  );
}
export default ConversionPrompt;
