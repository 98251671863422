import { useState } from 'react';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { StyledClassicView } from './style';
import useTexts from './useTexts';
import ExternalModal from '../../../externalModal';
import { APP_CONSTANTS, TICKETS_OPEN_URL } from '../../../../constants';
import { removeCookie, setCookie } from '../../../../common/utils/utils';

function OpenInClassic(props) {
  const { srId, handleCloseParent, pendoEvent} = props;
  const [open, setOpen] = useState(false);
  const { openInClassicText } = useTexts();
  const url = TICKETS_OPEN_URL.CLASSIC(srId);

  const trackEvents = useAnalytics();
  const handleClickOpen = () => {
    trackEvents(pendoEvent, { srId });
    setOpen(true);
    setCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE, 'true');
  };

  const handleClickClose = () => {
    setOpen(false);
    handleCloseParent();
    removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
  };

  return (
    <StyledClassicView onClick={handleClickOpen}>
      <button data-testid="open-in-classic">
        <span>{openInClassicText}</span>
      </button>
      <ExternalModal url={url} open={open} onClose={handleClickClose} />
    </StyledClassicView>
  );
}
export default OpenInClassic;
