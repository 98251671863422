import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_TRACK_EVENTS } from 'common/utils/eventMapping';
import { useRouter } from '@tanstack/react-router';
import { setToasterMessage } from 'store/globalSlice';
import ListItemIcon from '@mui/material/ListItemIcon';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"; // Bullet icon
import { debounce } from 'lodash';
import MessagePrompt from 'common/components/messagePrompt';
import { SR_TYPES, SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useAuditLog';
import { useDuplicateSR } from 'remote-state/ticketServiceHooks';
import { ReactComponent as AlerIcon } from 'images/icons/alertIcon.svg';
import { ActivePopupName, selectIsPopupActive } from 'store/activePopups';
import useDisableActivePopup from 'common/utils/hooks/useDisableActivePopup';
import { StyledDuplicateTicket, StyledListItem, StyledList } from './style';
import useTexts from './useTexts';
import { useGetSrRelatedItems } from '../../../resolutionPanel/middlePanel/relatedItems/hooks/useRelatedItems';

const TOASTER_SHOW_TIME = 10000; // 10s

function DuplicateTicket(props) {
  const { handleCloseParent, srId, srType } = props;
  const {
    duplicateTicketButtonText,
    duplicateTicketToasterMainText,
    duplicateTicketToasterSuccessMesasge,
    duplicateTicketToasterErrorMesasge,
    relatedItemsMessage,
    actionItemsMessage,
    duplicatePromptTitle,
    duplicatePromptOkText,
    duplicatePromptCancelText,
  } = useTexts();
  const { data: relatedItems } = useGetSrRelatedItems(srId);
  const isDuplicateWarningPromptActive = useSelector(selectIsPopupActive(ActivePopupName.DUPLICATE_ITEMS));
  const disableDuplicateWarningPopup  = useDisableActivePopup(ActivePopupName.DUPLICATE_ITEMS);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [isDontShowMeAgainChecked, setIsDontShowMeAgainChecked] = useState(false);
  const dispatch = useDispatch();
  const { getTicketAuditLogs, clearTicketAuditLog } = useAuditLog();
  const { duplicateSR } = useDuplicateSR(getTicketAuditLogs);
  const hasWorkflow = Boolean(SR_TYPE_CODE_MAP[srType] !== SR_TYPES.incident);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    datadogRum.addAction(DATADOG_TRACK_EVENTS.DUPLICATE_CANCELLED_FROM_POPUP);

    handleCloseParent();
  
    setOpen(false);
  };

  const handleError = () => {
    setOpen(false);
  };

  const handleGoToNewTicket = debounce((id) => {
    // clear the audit log before redirect to a new ticket
    clearTicketAuditLog();
    router.navigate({
      search: { id },
    });
  }, 2000);

  const handleDuplicate = async () => {
    try {

      if (isDontShowMeAgainChecked) {
        await disableDuplicateWarningPopup();
      }

      const newDuplicatedId = await duplicateSR(srId);

      if (newDuplicatedId) {
        handleClickClose();
        dispatch(setToasterMessage({
          message: `${duplicateTicketToasterMainText} #${srId} ${duplicateTicketToasterSuccessMesasge}`,
          showFor: TOASTER_SHOW_TIME,
          id: srId,
        }));
        handleGoToNewTicket(newDuplicatedId);
      }
    } catch {
      handleError();
      dispatch(setToasterMessage({
        message: duplicateTicketToasterErrorMesasge,
        showFor: TOASTER_SHOW_TIME,
        type: 'error',
      }));
    }
  };

  const handlePromptOkClick = () => {
    datadogRum.addAction(DATADOG_TRACK_EVENTS.DUPLICATE_DONE_FROM_POPUP);

    handleDuplicate(); 
  };

  const handleDuplicateClick = () => {
    datadogRum.addAction(DATADOG_TRACK_EVENTS.DUPLICATE_ACTION_ITEM_CLICK);

    if (isDuplicateWarningPromptActive && (relatedItems.count > 0 || hasWorkflow)) {
      handleClickOpen();
    } else {
      handleDuplicate();
    }
  };

  const handleCheckboxChange = () => {
    setIsDontShowMeAgainChecked((prevState) => !prevState);
  };

  return (
    <>
      <StyledDuplicateTicket
        onClick={handleDuplicateClick}
        data-testid="duplicate-ticket-button"
      >
        <button className="duplicate-ticket">
          <span className="delete-ticket-span" data-testid="convert-ticket-span">{duplicateTicketButtonText}</span>
        </button>
      </StyledDuplicateTicket>
      <MessagePrompt
        open={open}
        Icon={AlerIcon}
        style={{ MuiPaperRoot: { maxWidth: 520, width: 520 } }}
        title={duplicatePromptTitle}
        btnOkText={duplicatePromptOkText}
        btnCancelText={duplicatePromptCancelText}
        showCancelBtn
        showDontShowMeAgain
        isCheckboxChecked={isDontShowMeAgainChecked}
        onOkClick={handlePromptOkClick}
        onClose={handleClickClose}
        onToggleCheckbox={handleCheckboxChange}
      >
        <StyledList>
          {[relatedItemsMessage, actionItemsMessage].map((text) => (
            <StyledListItem key={text}>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              {text}
            </StyledListItem>
          ))}
        </StyledList>
      </MessagePrompt>
    </>
  );
}

DuplicateTicket.propTypes = {
  handleCloseParent: PropTypes.func.isRequired,
  srId: PropTypes.string.isRequired,
  srType: PropTypes.string.isRequired,
};

export default DuplicateTicket;
