import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    duplicateTicketButtonText: texts?.['spaces.header.actions.duplicateTicket.duplicate.button'],
    duplicateTicketToasterMainText: texts?.['spaces.duplicateTicket.toaster.main.text'],
    duplicateTicketToasterSuccessMesasge: texts?.['spaces.duplicateTicket.toaster.success.text'],
    duplicateTicketToasterErrorMesasge: texts?.['spaces.duplicateTicket.toaster.error.text'],
    relatedItemsMessage: texts?.['spaces.duplicateTicket.messagePrompt.relatedItems.text'],
    actionItemsMessage: texts?.['spaces.duplicateTicket.messagePrompt.actionItems.text'],
    duplicatePromptTitle:  texts?.['spaces.duplicateTicket.messagePrompt.title.text'],
    duplicatePromptOkText: texts?.['spaces.duplicateTicket.messagePrompt.ok.text'],
    duplicatePromptCancelText: texts?.['spaces.duplicateTicket.messagePrompt.cancel.text'],

  };

  return componentTexts;
}
