import React, { ReactElement, useMemo, useState, useRef } from 'react';
import Tooltip from 'common/components/tooltip';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { Popper, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';

import useServiceRequestTypeList from 'common/utils/hooks/useServiceRequestTypeList';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { TRACK_EVENTS } from 'constants/trackEvents';
import theme from 'features/theme/themes/lightTheme';
import useTexts from '../useTexts';
import {
  StyledFieldDropdownValue,
  StyledFieldKey,
  StyledTemplateWrapper,
  StyledFieldsWrapper,
  StyledFieldValue,
  ChevronIcon,
} from './style';

type ServiceRequestTypeSelectProps = { srType: string; onTypeChange: (type: string) => void };

const menuItemsStyles = {
  padding: '12px 0 12px 24px;',
  width: '225px;',
  lineHeight: '20px;',
  fontSize: '14px;',
  fontFamily: 'Roboto-Regular',
  // @ts-expect-error
  '&:hover': { backgroundColor: theme?.palette?.grey?.bt_alt_grey_light },
};

const paperStyles = {
  boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35);',
  borderRadius: '10px;',
  marginLeft: '48px',
};

const ServiceRequestTypeSelect = ({ srType, onTypeChange }: ServiceRequestTypeSelectProps): ReactElement => {
  const { headerTypeText, incidentText, problemText, requestText, changeText } = useTexts();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [open, setOpen] = useState(false);
  const ticketTypes = useServiceRequestTypeList();
  const trackEvents = useAnalytics();

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleItemClick = (ticketType) => (event) => {
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
    onTypeChange(ticketType);
  };

  const srTypeToDisplay = useMemo(() => {
    switch (srType) {
      case 'request':
        return requestText;
      case 'problem':
        return problemText;
      case 'change':
        return changeText;
      case 'incident':
      default:
        return incidentText;
    }
  }, [srType, incidentText, requestText, problemText, changeText]);

  const { data: isSRTypeSelectionEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.ENABLE_SR_TYPE_CHANGE_ON_TICKET_CREATION,
    defaultValue: false,
  });

  if (!isSRTypeSelectionEnabled) {
    return (
      <StyledFieldsWrapper>
        <StyledFieldKey>{headerTypeText || 'Type'}</StyledFieldKey>
        <StyledFieldValue>{srTypeToDisplay}</StyledFieldValue>
      </StyledFieldsWrapper>
    );
  }

  const handleOpenMenu = () => {
    trackEvents(TRACK_EVENTS.CHANGE_SR_TYPE_CHEVRON_CLICK_FROM_NEW_TICKET);
    toggleMenu();
  };

  return (
    <StyledFieldsWrapper>
      <StyledTemplateWrapper onClick={handleOpenMenu} data-testid="srType-select">
        <StyledFieldKey>{headerTypeText || 'Type'}</StyledFieldKey>
        <StyledFieldDropdownValue
          // @ts-expect-error
          ref={anchorRef}
        >
          <Tooltip data-cy="selected-sr-type" isTruncatedText text={srTypeToDisplay} placement="top-start" />
          <ChevronIcon
            // @ts-expect-error
            open={open}
          />
        </StyledFieldDropdownValue>
      </StyledTemplateWrapper>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start">
        <Paper sx={paperStyles}>
          <ClickAwayListener onClickAway={toggleMenu}>
            <MenuList sx={{ padding: '12px 0;' }}>
              {ticketTypes.map(({ type: ticketType, title }) => (
                <MenuItem data-testid={`sr-type-${ticketType}`} key={ticketType} onClick={handleItemClick(ticketType)} sx={menuItemsStyles}>
                  {title}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </StyledFieldsWrapper>
  );
};

export default ServiceRequestTypeSelect;
