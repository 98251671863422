import { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as ActionsIcon } from 'images/icons/actions-icon.svg';
import { isQueue } from 'common/utils/utils';
import {
  useShowLinkToClassic,
  useSrArchivingEnabled,
  useSrConversionEnabled,
  useDuplicateTicketEnabled,
} from 'remote-state/accountSettingsServiceHooks';
import { TRACK_EVENTS } from 'constants/trackEvents';
import DeleteTicket from './deleteTicket';
import ArchiveTicket from './archiveTicket';
import OpenInClassic from './openInClassic';
import DuplicateTicket from './duplicateTicket';
import { StyledActions, StyledBottomActions, StyledMenu } from './style';
import ConvertTicket from './convertTicket';
import OpenInNewTab from './openInNewTab';

export default function Actions(props) {
  const {
    ticketQueueUrl,
    onClickParent,
    srId,
    srType,
    disabledDeleteAction,
    disabledArchiveAction,
    handleMouseEnter,
    handleMouseLeave,
    hovered,
    btnClassName = '',
    deleteTicketSx,
    menuStyles = {},
    footer = null,
    pendoEvent = TRACK_EVENTS.OPEN_IN_CLASSIC_FROM_QUEUE_CLICK,
    isFromQueue = true,
    isArchived,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const open = Boolean(anchorEl);

  const showLinkToClassic = useShowLinkToClassic();
  const isSrConversionEnable = useSrConversionEnabled(srType);
  const isQueuePage = useMemo(() => isQueue(), []);

  const amountOfOpenButtons = (showLinkToClassic ? 1 : 0) + (isQueuePage ? 1 : 0);

  const showArchiveBtn = useSrArchivingEnabled() && !isQueuePage && !disabledArchiveAction;

  const showDuplicatesBtn = useDuplicateTicketEnabled() && !isQueuePage;

  const handleClick = () => {
    setAnchorEl(ref.current);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <StyledActions
      className="actionsClass"
      btnClassName={btnClassName}
      isActive={anchorEl}
      data-testid="header-actions"
      isArchived={isArchived}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            ref={ref}
            id="actions-icon-button"
            onClick={handleClick}
            className={`${anchorEl || hovered ? 'active ' : ''}${btnClassName}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ActionsIcon />
          </IconButton>
          <StyledMenu
            id="action-menu"
            MenuListProps={{
              'aria-labelledby': 'action-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={2}
            menuStyles={menuStyles}
            amountOfOpenButtons={amountOfOpenButtons}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {isQueuePage && (
              <Box className="top-actions">
                <OpenInNewTab srId={srId} handleCloseParent={handleClose} onClickParent={onClickParent} />
                {showLinkToClassic && (
                  <OpenInClassic srId={srId} handleCloseParent={handleClose} pendoEvent={pendoEvent} />
                )}
              </Box>
            )}
            <StyledBottomActions isQueuePage={isQueuePage}>
              {isSrConversionEnable && !isArchived && (
                <ConvertTicket
                  ticketQueueUrl={ticketQueueUrl}
                  handleCloseParent={handleClose}
                  onClickParent={onClickParent}
                  srId={srId}
                  srType={srType}
                  isFromQueue={isFromQueue}
                />
              )}
              {showDuplicatesBtn && (
                <DuplicateTicket srId={srId} srType={srType} handleCloseParent={handleClose} />
              )}
              {showArchiveBtn && (
                <ArchiveTicket
                  ticketQueueUrl={ticketQueueUrl}
                  handleCloseParent={handleClose}
                  onClickParent={onClickParent}
                  srId={srId}
                  customSx={deleteTicketSx}
                  isArchived={isArchived}
                />
              )}
              {!disabledDeleteAction && (
                <DeleteTicket
                  ticketQueueUrl={ticketQueueUrl}
                  handleCloseParent={handleClose}
                  onClickParent={onClickParent}
                  srId={srId}
                  customSx={deleteTicketSx}
                />
              )}
            </StyledBottomActions>
            {!isQueuePage && showLinkToClassic && (
              <Box>
                <Divider />
                <OpenInClassic srId={srId} handleCloseParent={handleClose} pendoEvent={pendoEvent} />
              </Box>
            )}
            {footer && (
              <Box>
                <Divider />
                {footer}
              </Box>
            )}
          </StyledMenu>
        </Box>
      </ClickAwayListener>
    </StyledActions>
  );
}
