import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import MessagePrompt from 'common/components/messagePrompt';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { TRACK_EVENTS } from 'constants/trackEvents';
import { TICKET_EVENTS } from '../../../../constants';
import { StyledArchiveTicket } from './style';
import useTexts from './useTexts';
import { useArchiveSR } from '../../../../remote-state/ticketServiceHooks';
import { useAuditLog } from '../../../resolutionPanel/middlePanel/auditLog/hooks';

const TOASTER_SHOW_TIME = 15000; // 15s

function ArchiveTicket(props) {
  const { handleCloseParent, srId, customSx, isArchived } = props;
  const {
    archiveTicketButtonText,
    archiveTicketConfirmText,
    archiveTicketCancelText,
    archiveTicketWarningMessageTitle,
    archiveTicketWarningMessageText,
    archiveTicketMessageText,
    archiveTicketErrorMessageText,
    restoreTicketButtonText,
    restoreTicketWarningMessageTitle,
    restoreTicketWarningMessageText,
    restoreTicketConfirmText,
    restoreTicketMessageText,
    restoreTicketErrorMessageText,
    copyURL,
  } = useTexts(srId);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const trackEvents = useAnalytics();
  const { getTicketAuditLogs } = useAuditLog();
  const { archiveSR } = useArchiveSR(getTicketAuditLogs);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    handleCloseParent();
    setOpen(false);
    trackEvents(TRACK_EVENTS.ARCHIVE_CANCELLED_FROM_POPUP, { srId });
  };

  const handleError = () => {
    setOpen(false);
  };

  const archivedTicketMessage = `
    <div class="message-wrapper">
      ${
        isArchived
          ? restoreTicketMessageText.replace('__srId__', srId)
          : archiveTicketMessageText.replace('__srId__', srId)
      }
      <div class="buttons-wrapper">
        <button class="btn-link" data-testid="copyLink">
          ${copyURL}
        </button>
      </div>
    </div>
  `;

  const handleArchive = async () => {
    try {
      handleClickClose();
      await archiveSR({ srId, archive: !isArchived });
      dispatch(
        setToasterMessage({
          message: archivedTicketMessage,
          showFor: TOASTER_SHOW_TIME,
          onClickFunctionName: TICKET_EVENTS.TICKET_ARCHIVED,
          id: srId,
        }),
      );
      trackEvents(TRACK_EVENTS.ARCHIVE_DONE_FROM_POPUP, { srId });
    } catch {
      handleError();
      dispatch(
        setToasterMessage({
          message: isArchived ? restoreTicketErrorMessageText : archiveTicketErrorMessageText,
          showFor: TOASTER_SHOW_TIME,
          type: 'error',
        }),
      );
    }
  };

  return (
    <StyledArchiveTicket sx={customSx} onClick={handleClickOpen}>
      <button className="archive-ticket">
        <span className="archive-ticket-span">{isArchived ? restoreTicketButtonText : archiveTicketButtonText}</span>
      </button>
      <MessagePrompt
        open={open}
        title={
          isArchived
            ? restoreTicketWarningMessageTitle.replace('__srId__', srId)
            : archiveTicketWarningMessageTitle.replace('__srId__', srId)
        }
        children={isArchived ? restoreTicketWarningMessageText : archiveTicketWarningMessageText}
        btnOkText={isArchived ? restoreTicketConfirmText : archiveTicketConfirmText}
        btnCancelText={archiveTicketCancelText}
        onOkClick={handleArchive}
        showCancelBtn
        showIcon={false}
        onClose={handleClickClose}
      />
    </StyledArchiveTicket>
  );
}
export default ArchiveTicket;
